import { Grid } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { transparentize } from "@chakra-ui/theme-tools";
import { Button } from "@chakra-ui/button";
import { Fade } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import PropTypes from "prop-types";

TopBar.proptypes = {
  pageHeadline: PropTypes.string,
};

export default function TopBar({ pageHeadline }) {
  const { isOpen, onToggle } = useDisclosure();
  const currentBoundingofHeading = null;

  const checkBoundryOfHeading = () => {
    const heading = document.querySelector("main .chakra-heading");
    const headingbounding = heading.getBoundingClientRect();
    return headingbounding;
  };

  const toggleHeadline = () => {
    const headingbounding = checkBoundryOfHeading();

    if (currentBoundingofHeading !== headingbounding.top) {
      if (headingbounding.top <= 0 && isOpen === false) {
        currentBoundingofHeading = headingbounding.top;
        onToggle();
      } else if (headingbounding.top >= 0 && isOpen === true) {
        currentBoundingofHeading = headingbounding.top;
        onToggle();
      }
    }
  };

  if (typeof document !== "undefined") {
    document.addEventListener("scroll", toggleHeadline);
  }

  return (
    <Grid
      as="aside"
      gridTemplateColumns="65px 1fr 50px"
      alignItems="center"
      justifyItems="center"
      bg={useColorModeValue(
        transparentize("white", 0.75),
        transparentize("gray.800", 0.75),
      )}
      backdropFilter="blur(10px)"
      position="sticky"
      top="0"
      zIndex="10"
      paddingX="1.5"
      overflowY="hidden">
      <Button
        height="40px"
        padding="0"
        fontSize="sm"
        fontWeight="light"
        bg="none"
        _hover={{ bg: "none" }}
        _active={{ bg: "none" }}
        onClick={() => {
          if (window.history.length <= 1) {
            window.location.href = "/";
          } else {
            window.history.back();
          }
        }}>
        <ChevronLeftIcon boxSize="20px" /> zurück
      </Button>
      <Fade in={isOpen}>
        <Heading as="h2" fontSize="sm">
          {pageHeadline}
        </Heading>
      </Fade>
    </Grid>
  );
}
