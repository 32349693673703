import Head from "next/head";
import { Center, Flex } from "@chakra-ui/layout";
import Navbar from "@components/Navbar";
import theme from "src/theme";
import PropTypes from "prop-types";
import TopBar from "@components/TopBar";
import {
  withAuthUser,
  AuthAction,
  withAuthUserTokenSSR,
} from "next-firebase-auth";

Layout.propTypes = {
  pageTitle: PropTypes.string,
  pageHeadline: PropTypes.string,
  selectedNavTab: PropTypes.string,
  showTopBar: PropTypes.bool,
  hideNavBar: PropTypes.bool,
  alignItems: PropTypes.string,
};

export default function Layout({
  children,
  pageTitle = "Fenske App",
  pageHeadline = pageTitle,
  selectedNavTab,
  showTopBar = false,
  hideNavBar = false,
  alignItems = "center",
}) {
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      {showTopBar && (
        <TopBar pageHeadline={pageHeadline} maxWidth="breakpoints.sm" />
      )}
      <Flex
        direction="column"
        justifyContent="space-between"
        maxWidth="breakpoints.sm"
        margin="0 auto"
        marginBottom="calc( 70px + env(safe-area-inset-bottom))">
        <Center as="main" mx="2" flexDirection="column" alignItems={alignItems}>
          {children}
        </Center>
      </Flex>
      {!hideNavBar && (
        <Navbar selectedNavTab={selectedNavTab} maxWidth="breakpoints.sm" />
      )}
    </>
  );
}

export const getLayout = (page) => <Layout>{page}</Layout>;
