import { Box, Flex, Text, ListItem, Image } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import PropTypes from "prop-types";

MenuCardItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fullScreenBgImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  image: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundRepeat: PropTypes.string,
};

export function MenuCardItem({
  children,
  fullScreenBgImage,
  backgroundColor,
  image,
  linkTo,
  backgroundSize = "cover",
  backgroundPosition = "right",
  backgroundRepeat = "no-repeat",
}) {
  return (
    <ListItem marginY="6">
      <Link href={linkTo} passHref>
        <Flex as="a" cursor="pointer" justifyContent="center">
          <Flex
            justifyContent="space-between"
            width="100%"
            maxWidth="350px"
            height="125px"
            bg={backgroundColor}
            padding="4"
            borderRadius="2xl"
            boxShadow="md"
            backgroundImage={fullScreenBgImage}
            backgroundSize={backgroundSize}
            backgroundPosition={backgroundPosition}
            backgroundRepeat={backgroundRepeat}>
            <Text fontSize="xl" fontWeight="semibold">
              {children}
            </Text>
            <Image src={image} margin="-4" objectFit="cover" alt="" />
          </Flex>
        </Flex>
      </Link>
    </ListItem>
  );
}
