import React from "react";
import { List } from "@chakra-ui/react";
import { MenuCardItem } from ".";
import PropTypes from "prop-types";

MenuCardList.propTyes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export function MenuCardList({ menuItems }) {
  return (
    <List width="95%">
      {menuItems.map((menuItem, index) => (
        <MenuCardItem linkTo="#" key={index}>
          Menüpunkt 1
        </MenuCardItem>
      ))}
    </List>
  );
}
