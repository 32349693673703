import { Heading, List } from "@chakra-ui/react";
import {
  withAuthUser,
  withAuthUserTokenSSR,
  AuthAction,
  useAuthUser,
} from "next-firebase-auth";
import { useRouter } from "next/router";
import Layout from "@components/layouts";
import React, { useEffect } from "react";
import { MenuCardItem } from "@components/MenuCard";

function Home() {
  const user = useAuthUser();
  const router = useRouter();

  useEffect(() => {
    if (localStorage.getItem("putztInviteCode")) {
      router.push(
        `/putzt/join?inviteCode=${localStorage.getItem("putztInviteCode")}`,
      );
      localStorage.removeItem("putztInviteCode");
    }
  }, []);

  useEffect(() => {
    if (
      user.firebaseUser !== null &&
      user.clientInitialized &&
      !user.displayName
    ) {
      alert(
        "Gib deinen Namen in den Einstellungen ein, damit alle wissen, wer du bist.",
      );
      router.push("/settings");
    }

    router.push("/putzt");
  }, [user]);

  return (
    <List width="95%" marginTop="10">
      <MenuCardItem
        linkTo="/putzt"
        backgroundColor="var(--chakra-colors-blue-400)">
        Fenske.Putzt
      </MenuCardItem>
      <MenuCardItem linkTo="#" backgroundColor="var(--chakra-colors-gray-400)">
        Fenske.Glotzt <br />
        Coming soon
      </MenuCardItem>
    </List>
  );
}

Home.getLayout = (page) => (
  <Layout
    pageTitle="Willkommen bei der Fenske App"
    selectedNavTab="Home"
    showTopBar
    alignItems="flex-start">
    <Heading textAlign="left" width="100%" id="thisheadline" marginBottom="4">
      Willkommen bei der Fenske App
    </Heading>
    {page}
  </Layout>
);

export default withAuthUser({
  // whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
})(Home);
