import React from "react";
import { Tabs, TabList, Tab } from "@chakra-ui/tabs";
import { useColorModeValue } from "@chakra-ui/color-mode";
import Link from "next/link";
import { transparentize } from "@chakra-ui/theme-tools";

export default function Navbar({ selectedNavTab, maxWidth }) {
  const tabmenuitems = [
    {
      name: "Home",
      icon: "",
      linkTo: "/",
    },
    {
      name: "Settings",
      icon: "",
      linkTo: "/settings",
    },
  ];

  let selectedDefaultIndex = tabmenuitems.findIndex(
    (tabitem) => tabitem.name === selectedNavTab,
  );

  const borderTop = `0.5px solid ${useColorModeValue(
    "var(--chakra-colors-gray-300)",
    "var(--chakra-colors-gray-700)",
  )}`;
  return (
    <Tabs
      as="nav"
      position="fixed"
      bottom="0"
      border="none"
      paddingBottom="env(safe-area-inset-bottom, 15px)"
      isFitted
      width="100%"
      zIndex="1"
      maxWidth={maxWidth}
      bg={useColorModeValue(
        transparentize("white", 0.75),
        transparentize("gray.800", 0.75),
      )}
      _active={useColorModeValue(
        transparentize("white", 0.75),
        transparentize("gray.800", 0.75),
      )}
      backdropFilter="blur(10px)"
      borderTop={borderTop}
      defaultIndex={selectedDefaultIndex === -1 ? null : selectedDefaultIndex}>
      <TabList height="45px" border="none">
        {tabmenuitems.map((tabitem, index) => (
          <Link href={tabitem.linkTo} passHref key={index}>
            <Tab as="a" key={tabitem.name} boxSizing="border-box" mb="0">
              {tabitem.name}
            </Tab>
          </Link>
        ))}
      </TabList>
    </Tabs>
  );
}
